import React       from 'react';
import PropTypes   from 'prop-types';

import {
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import { AvField } from 'availity-reactstrap-validation';

const propTypes = {
    spinColor:      PropTypes.string,
    spinConfig:     PropTypes.object,
    spinAlignment:  PropTypes.string
};

function FormRow({
                     label       = 'Label',
                     type       = 'text',
                     name ,
                     required = true,
                     readonly = false,
                     errorMessage = "Required",
                     helpMessage = "",
                     placeHolder="",
                     data       ,
                     changeInput,

                 }) {


    return (
        <Row>
            <Label sm="2">{label}</Label>
            <Col sm="7">
                <FormGroup >
                    <AvField
                        name={name}
                        type={type} // text  or textarea
                        value = {data}
                        required = {required}
                        readOnly = {readonly}
                        errorMessage={errorMessage}
                        helpMessage={helpMessage}
                        placeHolder={placeHolder}
                        autoComplete="none"
                        onChange={e =>
                            changeInput(e, name)
                        }
                    />
                </FormGroup>
            </Col>

            {required && <Col className="label-on-right" tag="label" sm="3">
                <code>required</code>
            </Col>}

        </Row>
    );
}

FormRow.propTypes = propTypes;

export default FormRow;