import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";
import PictureUpload from '../../components/CustomUpload/PictureUpload'
import ImageUpload from "../../components/CustomUpload/ImageUpload";


export default class SensorAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            managertypelist:[],
            landlist:[],
            land:"",
            name: "",
            sensorid:"",
            _id : "",
            x_v: "",
            x_t: "",
            x_ws: "",
            sid: "",
            fl: "",
            state: "",
            vids: "",
            i_mst: "",
            x_mst: "",
            zoneImageURL:null,
            zoneImageFile:null,
            zid: ""

        };
        this.engine = new RequestEngine();
    }


    handlePictureFileChange = (file) => {
        this.setState({ zoneImageFile: file })

    }
    handleImageDelete =async (imageNB)=>{
        this.engine.deleteZoneImage({zoneID:this.state._id,imageNB},(response)=>{
            if(response){
                switch(parseInt(imageNB)){
                    case 1:this.setState({zoneImageURL:undefined});break;
                }
            }
        })
    }
    componentDidMount() {
        const edit = this.props.location.data
        //console.log( "edit:  ", edit.land.landid)
        if (edit){
            this.setState({
                ...edit,
                managertype:edit.user && edit.user._id,
                land:edit.land && edit.land._id,
                _id: edit._id,
                zoneImageURL:edit.zoneImage,
                zid: edit.land.landid
            });


        }

        this.prepareData()
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("user");
        if(responseuni){
            const data = responseuni.data.data;
            let managertypelist = []
            data.map(p => {
                managertypelist.push( { value: p._id, label: p.name })
                // console.log(p)
            }
                
            )

            this.setState({
                managertypelist: managertypelist
            });
        }


         responseuni = await this.engine.getItemlistDirect("land");
        if(responseuni){
            const data = responseuni.data.data;
            // console.log(responseuni.data)
            let landlist = []
            data.map(p => {
                landlist.push( { value: p._id, label: p.name, zid: p.landid })
                //console.log("hellloooo thereerrrr pppp: ", p)
                console.log(p.landid)
            }
                
            )

            this.setState({
                landlist: landlist
            });
        }
    }



    handleValidSubmit = () => {
        const formData = new FormData();
        const {land,name, _id ,x_v,x_t,x_ws,sid,fl,state,vids,i_mst,x_mst,fs, zid}=this.state;
        const edit = this.props.location.data
        if(edit){
            formData.append('fs',fs);
        }
        formData.append('managertype',this.state.managertype);
        formData.append('name',name);
        formData.append('land',land);
        formData.append('sid',sid);
        formData.append('_id',_id);
        formData.append('x_v',x_v);
        formData.append('x_t',x_t);
        formData.append('x_ws',x_ws);
        formData.append('fl',fl);
        formData.append('state',state);
        formData.append('vids',vids);
        formData.append('i_mst',i_mst);
        formData.append('x_mst',x_mst);
       

        if(this.state.zoneImageFile){
            formData.append('zoneImage',this.state.zoneImageFile);
        }
        this.setState({
            btnloading: true
        })
        // this.engine.saveSensor(this.state,(response) => {
        this.engine.saveSensor(zid, formData,(response) => {
            console.log(response.status);
            if(response.status === 200){
                this.props.history.push('/admin/sensor');
            }
            // this.setState({
            //     btnloading: false
            // })
        });

    };




    renderManagerinfo(){

const {
    sid,

    state,
    vids,
    i_mst,
    x_mst,
    zid
    } = this.state
        return (<>
            <Row>
                <Label sm="2">User</Label>
                <Col sm="7">
                    <FormGroup>


                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="managertype"
                            isMulti ={false}
                            isSearchable ={false}
                            value={this.state.managertypelist.find(v => v.value === this.state.managertype)}
                            onChange={data =>
                                this.setState({ managertype: data.value })
                            }
                            options={this.state.managertypelist}
                            placeholder="Please select "
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Label sm="2">Land</Label>
                <Col sm="7">
                    <FormGroup>


                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="managertype"
                            isMulti ={false}
                            isSearchable ={false}
                            value={this.state.landlist.find(v => v.value === this.state.land)}
                            onChange={data => {
                                this.setState({ land: data.value })
                                // console.log(this.state.landlist)
                            }
                                
                            }
                            
                            options={this.state.landlist}
                            placeholder="Please select "
                        />
                    </FormGroup>
                </Col>
            </Row>
            <FormRow label="name" name="name" data={this.state.name} changeInput={this.changeInput.bind(this)} />
            {/* <FormRow label="zid" name="zid" data={this.state.landlist.zid} changeInput={this.changeInput.bind(this)} /> */}

            <FormRow label="Sensor id - sid" name="sid" type={"number"} data={sid} changeInput={this.changeInput.bind(this)} />
            {2>3 && <FormRow label="State "   placeHolder="0 auto, 1 on, 2 off" type={"number"} name="state" data={state} changeInput={this.changeInput.bind(this)} />}
            <FormRow label="Vids " placeHolder="1002,10003" name="vids" data={vids} changeInput={this.changeInput.bind(this)} />

            <FormRow label="minimum moisture" name="i_mst" type={"number"} data={i_mst} changeInput={this.changeInput.bind(this)} />
            <FormRow label="maximum moisture" name="x_mst" type={"number"} data={x_mst} changeInput={this.changeInput.bind(this)} />
            <FormRow label="Zid" name="zid" data={zid} changeInput={this.changeInput.bind(this)} readOnly />

            <div className="editLand-pictureContainer" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <PictureUpload fromLand={true} imageNB={1} handleImageDelete={this.handleImageDelete.bind(this)} setPictureFile={this.handlePictureFileChange.bind(this)} pictureURL={this.state.zoneImageURL} />
            </div>
        </>)
    }
    render() {
        // taking all the states
        const {    managertypelist,
            landlist,
            full_name,
            password,
            email,
            comments,
            phone,
            first_name,
            last_name,
            birthday,
            picture,
            gender,
            status,
            managertype,
            newpassword,
            btnloading,_id} = this.state;
        const {normaluser} = this.props
        const edit = this.props.location.data
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        {this.renderManagerinfo()}



                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


