import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';


export default class ForagingAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            title: "",
            _id : "",
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit){
            this.setState({
                hardwareid: edit.hardwareid,
                _id: edit._id,
            });
        }

    }


    handleValidSubmit = () => {

        this.setState({
            btnloading: true
        })
        this.engine.saveItem(this.state,"foraging",(response) => {
            console.log(response.status);
            if(response.status === 200){
                this.props.history.push('/admin/foraging');
            }
            this.setState({
                btnloading: false
            })
        });

    };

    render() {
        // taking all the states
        const { title,btnloading,_id} = this.state;
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="title" name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


