import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    Button
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import { connect } from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import { showMsgSuccess, showMsgDanger } from "../../core/redux/actions/showMsg";

import ReactTable from "react-table";

class FarmingTipsList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }


    handleEdit(index, dataid) {
        const data = this.state.dataSource[index].data
        this.props.history.push({
            pathname: '/admin/farmingtips/edit/' + dataid,
            data
        });

    }

    async handleDelete(valueid) {
        this.props.loadingAction(true)
        const response = await this.engine.deleteItem('farmingtips', valueid);
        if (response && response.status === 200) {
            this.handlePage();
        }
        this.props.loadingAction(false)
    }



    handleActions(index, item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem variant="danger" eventKey="2" onClick={() => this.handleEdit(index, item._id)}>Edit</DropdownItem>


                <DropdownItem variant="danger" eventKey="2" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>)
    }


    callPage() {
        this.engine.getFarmingTips((response) => {
            console.log(response.status);
            if (response.status === 200) {
                this.setState({
                    isLoading: false,
                    dataSource: response.data.map((item, key) => {
                        return {
                            id: key,
                            data: item,
                            _id: item._id,
                            title: item.title,
                            message:item.message,
                            created: this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key, item)
                            )
                        };
                    })
                }
                );
            }
            this.props.loadingAction(false);
        });
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }

    handleAdd() {
        this.props.history.push('/admin/farmingtips/add');
    }

    render() {

        const { dataSource, isLoading, } = this.state;
        if (isLoading) {
            return this.renderProgress()
        }


        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Farming Tips List</CardTitle>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.handleAdd()
                                    }>
                                        Add
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        columns={[
                                            {
                                                Header: "Title",
                                                accessor: "title"
                                            },
                                            {
                                                Header: "Message",
                                                accessor: "message",
                                                style: { 'whiteSpace': 'unset' }
                                            },

                                            {
                                                Header: "Created",
                                                accessor: "created",
                                                sortMethod: (a, b) => {
                                                    const [day1, month1, year1] = a.split('/');
                                                    const [day2, month2, year2] = b.split('/');
                                                    if (year1 > year2) { return 1; }
                                                    else if (year1 < year2) { return -1; }
                                                    if (month1 > month2) { return 1; }
                                                    if (month1 < month2) { return -1; }
                                                    if (day1 > day2) { return 1; }
                                                    if (day1 < day2) { return -1; }
                                                    return 0;
                                                }
                                            },
                                            {
                                                Header: "Actions",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload)),
    showMsgSuccess: (payload) => dispatch(showMsgSuccess(payload)),
    showMsgDanger: (payload) => dispatch(showMsgDanger(payload)),

});
export default connect(null, mapDispatchToProps)(FarmingTipsList);
