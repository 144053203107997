import React from "react";
import Dashboard from "./views/Dashboard.jsx";
import Login from "./views/login/Login.jsx";
import UserList from "./views/user/UserList"
import LandList from "./views/apiary/LandList"
import EditLand from "./views/apiary/EditLand"


import HiveAddUpdate from "./views/hive/HiveAddUpdate"
import CropAddUpdate from "./views/crops/CropAddUpdate"



import UserAddUpdate from "./views/user/UserAddUpdate";
import NotificationList from "./views/notification/NotificationList";

import SensorList from "./views/sensor/SensorList";
import SensorAddUpdate from "./views/sensor/SensorAddUpdate";


import OperationList from "./views/operation/OperationList";

import HintsAddUpdate from "./views/hints/HintsAddUpdate";



import ForagingList from "./views/foraging/ForagingList";
import ForagingAddUpdate from "./views/foraging/ForagingAddUpdate";
import TypeList from "./views/type/TypeList";
import TypeAddUpdate from "./views/type/TypeAddUpdate"
import CropList from "./views/crops/CropList";

import FarmingtipsList from "./views/farmingtips/FarmingtipsList.jsx";
import EditFarmingTip from "./views/farmingtips/EditFarmingTip.jsx";




const ManagerList = (props) => {
    return (
        <UserList
            manager={true}
            {...props}
        />
    );
}

const NormalUserAddUpdate = (props) => {
    return (
        <UserAddUpdate
            normaluser={true}
            {...props}
        />
    );
}

const routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon nc-bank",
        component: Dashboard,
        allow: ["admin"],
        menu: true,
        layout: "/admin"
    },



    {
        path: "/login",
        name: "Login",
        mini: "L",
        menu: false,
        component: Login,
        layout: "/auth"
    },
    {
        path: "/user",
        name: "Users",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-circle-10",
        component: UserList,
        layout: "/admin"
    },

    {
        path: "/land",
        name: "Land",
        menu: true,
        // allow: ["admin"],
        icon: "nc-icon nc-paper",
        component: LandList,
        layout: "/admin"
    },

    {
        path: "/sensor",
        name: "sensor",
        menu: true,
        // allow: ["admin"],
        icon: "nc-icon nc-paper",
        component: SensorList,
        layout: "/admin"
    },

    {
        path: "/profile",
        name: "profile",
        menu: true,
        allow: ["farmer"],
        icon: "nc-icon nc-single-02",
        component: UserAddUpdate,
        layout: "/admin"
    },


    {
        path: "/operationlist",
        name: "operationlist",
        //  menu: false,
        //  allow : ["admin"],
        icon: "nc-icon nc-paper",
        component: OperationList,
        layout: "/admin"
    },





    {
        path: "/hive/edit/:id",
        name: "Edit hive",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: HiveAddUpdate,
        layout: "/admin"
    },

    {
        path: "/sensor/edit/:id",
        name: "Edit sensor",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: SensorAddUpdate,
        layout: "/admin"
    },
    {
        path: "/land/edit/:id",
        name: "Edit land",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: EditLand,
        layout: "/admin"
    },
    {
        path: "/land/add",
        name: "add land",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: EditLand,
        layout: "/admin"
    },
    {
        path: "/sensor/add",
        name: "add sensor",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: SensorAddUpdate,
        layout: "/admin"
    },



    {
        path: "/crops",
        name: "Valves",
        menu: true,
        allow: ["admin"],
        icon: "nc-icon nc-badge",
        component: CropList,
        layout: "/admin"
    },
    {
        path: "/manager",
        name: "Manager",
        menu: true,
        allow: ["admin"],
        icon: "nc-icon nc-badge",
        component: ManagerList,
        layout: "/admin"
    },
    {
        path: "/farmingtips",
        name: "Farming Tips",
        menu: false,
        allow: ["admin"],
        icon: "nc-icon nc-paper",
        component: FarmingtipsList,
        layout: "/admin"
    },
    {
        path: "/farmingtips/add",
        name: "add farmingtips",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: EditFarmingTip,
        layout: "/admin"
    },
    {
        path: "/farmingtips/edit/:id",
        name: "edit farmingtips",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: EditFarmingTip,
        layout: "/admin"
    },

    {
        path: "/type/edit/:id",
        name: "Edit type",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: TypeAddUpdate,
        layout: "/admin"
    },
    {
        path: "/type/add",
        name: "add type",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: TypeAddUpdate,
        layout: "/admin"
    },


    {
        path: "/crops/edit/:id",
        name: "Edit Crops",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: CropAddUpdate,
        layout: "/admin"
    },
    {
        path: "/crops/add",
        name: "add Crops",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: CropAddUpdate,
        layout: "/admin"
    },

    {
        path: "/foraging/edit/:id",
        name: "Edit foraging",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ForagingAddUpdate,
        layout: "/admin"
    },
    {
        path: "/foraging/add",
        name: "add foraging",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ForagingAddUpdate,
        layout: "/admin"
    },


    {
        path: "/manager/edit/:id",
        name: "Edit manager",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: UserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/manager/add",
        name: "add manager",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: UserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/user/edit/:id",
        name: "Edit user",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: NormalUserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/user/add",
        name: "add user",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: NormalUserAddUpdate,
        layout: "/admin"
    },





    {
        path: "/hints/edit/:id",
        name: "Edit hints",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: HintsAddUpdate,
        layout: "/admin"
    },
    {
        path: "/hints/add",
        name: "add hints",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: HintsAddUpdate,
        layout: "/admin"
    },
    {
        path: "/notification",
        name: "notification",
        menu: false,
        allow: ["admin"],
        icon: "nc-icon nc-paper",
        component: NotificationList,
        layout: "/admin"
    },


];

export default routes;
