import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    Button
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import {showMsgSuccess,showMsgDanger} from "../../core/redux/actions/showMsg";

import ReactTable from "react-table";

class OperationList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }

    handleAdd() {
        this.props.history.push('/admin/apiary/add');
    }
    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data
        this.props.history.push({
            pathname:'/admin/apiary/edit/'+dataid,
            data
        });

    }

    async handleDelete(valueid) {
        this.props.loadingAction(true)
        const response = await this.engine.deleteItem('land',valueid);
        if(response && response.status === 200){
            this.handlePage();
        }
        this.props.loadingAction(false)
    }



    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>



                <DropdownItem variant="danger" eventKey="2" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>)
    }


    callPage() {
        this.engine.getLands((response) => {
            console.log(response.status);
            if(response.status === 200){
                this.setState({
                        isLoading: false,
                        dataSource: response.data.data.map((item, key) => {
                            return {
                                id: key,
                                data:item,
                                _id: item._id,
                                title: item.name ,

                                operation:"Operation "+(1+key),
                                location:item.location,
                                user:item.user && item.user.name,
                                created:this.renderDate(item.createdAt),

                            };
                        })
                    }
                );
            }
            this.props.loadingAction(false);
        });
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    render() {

        const {dataSource,isLoading} = this.state;
        if (isLoading) {
            return this.renderProgress()
        }


        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Operation List</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        columns={[
                                            {
                                                Header: "Name",
                                                accessor: "title"
                                            },


                                            {
                                                Header: "Operation",
                                                accessor: "operation"
                                            },

                                            {
                                                Header: "User",
                                                accessor: "user",
                                                // width: 150
                                            },
                                            {
                                                Header: "Created",
                                                accessor: "created"
                                            },

                                        ]}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload)),
    showMsgSuccess: (payload) => dispatch(showMsgSuccess(payload)),
    showMsgDanger: (payload) => dispatch(showMsgDanger(payload)),

});
export default connect(null, mapDispatchToProps)(OperationList);