import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    Button
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import {showMsgSuccess,showMsgDanger} from "../../core/redux/actions/showMsg";

import ReactTable from "react-table";

class NotificationList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }







    callPage() {
        this.engine.getNoti((response) => {
            console.log(response.status);
            if(response.status === 200){
                this.setState({
                        isLoading: false,
                        dataSource: response.data.data.map((item, key) => {
                            return {
                                id: key,
                                data:item,
                                _id: item._id,
                                notification:item.name,
                                user:item.user && item.user.name,
                                sensor:item.sensor && item.sensor.name,
                                created:this.renderDate(item.createdAt),

                            };
                        })
                    }
                );
            }
            this.props.loadingAction(false);
        });
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    render() {

        const {dataSource,isLoading} = this.state;
        if (isLoading) {
            return this.renderProgress()
        }


        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Notification List</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        columns={[
                                            {
                                                Header: "User",
                                                accessor: "user",
                                                // width: 150
                                            },
                                            {
                                                Header: "Sensor",
                                                accessor: "sensor"
                                            },
                                            {
                                                Header: "Notification",
                                                accessor: "notification"
                                            },

                                            {
                                                Header: "Created",
                                                accessor: "created"
                                            },
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload)),
    showMsgSuccess: (payload) => dispatch(showMsgSuccess(payload)),
    showMsgDanger: (payload) => dispatch(showMsgDanger(payload)),

});
export default connect(null, mapDispatchToProps)(NotificationList);
