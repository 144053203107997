import React from "react";
import defaultImage from "../../assets/img/default-avatar.png";
import image_placeholder from '../../assets/img/image_placeholder.jpg';
class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.pictureURL ? this.props.pictureURL : this.props.fromLand ? image_placeholder : defaultImage
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
    this.props.setPictureFile(file);
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.pictureURL !== prevProps.pictureURL) {
      this.setState({ imagePreviewUrl: this.props.pictureURL ? this.props.pictureURL : this.props.fromLand ? image_placeholder : defaultImage })
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  render() {
    let style = {}
    if (this.props.fromLand) { style = { height: '132px', borderRadius: '0%',position:'relative', width: '250px' } }
    else{style={border:'0',background:'white',position:'relative',borderRadius:'0'}}
    return (
      <div className="picture-container">
        <div className="picture" style={style}>
          <img
            src={this.state.imagePreviewUrl}
            className="picture-src"
            alt="..."
            style={{
              height: '100%',
              borderRadius:this.props.fromLand ? '0%':'50%'
            }}
          />
          <input name="picture" type="file" onChange={e => this.handleImageChange(e)} />
          {this.props.fromLand &&this.props.pictureURL&& <span style={{background:'red',color:'white',position:'absolute',top:'10px',right:'5px',fontSize:'1.2rem',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center',height:'22px',width:'22px'}} onClick={()=>this.props.handleImageDelete(this.props.imageNB)}>x</span>}
          {!this.props.fromLand &&this.props.pictureURL&& <span style={{background:'red',color:'white',position:'absolute',top:'0px',right:'0px',fontSize:'1.2rem',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center',height:'22px',width:'22px'}} onClick={()=>this.props.handleImageDelete(this.props.imageNB)}>x</span>}
        </div>
        <h6 className="description">Choose Picture</h6>
       
      </div>
    );
  }
}

export default PictureUpload;
