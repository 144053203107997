import axios from "axios/index";
import Constants from "../core/Constants";

class RequestEngine {
    constructor() {


        let token = sessionStorage.getItem('token');
        this.apiEngine = axios.create({
            baseURL: Constants.serverlink,
            timeout: Constants.timeout,
            headers: {
                'Content-Type': 'application/json',
                'token': token
            }
        }); //167.71.34.144
        // this.debugit();
    }


    debugit() {
        this.apiEngine.interceptors.request.use(request => {
            console.log('Starting Request', request);
            return request
        });

        this.apiEngine.interceptors.response.use(response => {
            console.log('Response:', response);
            return response
        })
    }




    toggleuserstatus(paramid, ismanager, callback) {
        if (ismanager) {
            ismanager = 1;
        } else {
            ismanager = 0;
        }
        const link = '/api/admin/toggleuserstatus/' + paramid + "/" + ismanager;

        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    saveSettings(data, callback) {
        const link = '/api/admin/settings/save';
        console.warn(link);
        this.apiEngine.post(link, data)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    deleteUser(id, callback) {
        const link = '/api/admin/user/delete/' + id;
        // console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    deleteManager(id, callback) {
        const link = '/api/admin/manager/delete/' + id;
        // console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    activateUser(id, callback) {
        const link = '/api/admin/user/activate/' + id;
        // console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    activateManager(id, callback) {
        const link = '/api/admin/manager/activate/' + id;
        // console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    




    deleteUsersubscribe(id, callback) {
        const link = '/api/admin/usersubscribe/delete/' + id;
        // console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async deleteItem(path, id) {
        const link = '/api/admin/' + path + '/delete/' + id;
        console.log(link)
        return await this.apiEngine.get(link)
    }


    async login(email, password) {
        const link = '/api/admin/login';
        const data = { email: email, password: password }
        console.warn(link);
        return await this.apiEngine.post(link, data);
    }





    getUsersubscribelist(callback) {
        const link = '/api/admin/usersubscribe/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getItemlistParam(path, param, callback) {
        const link = '/api/admin/' + path + '/list/' + param;
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getItemlist(path, callback) {
        const link = '/api/admin/' + path + '/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    async getItemlistDirect(path) {
        const link = '/api/admin/' + path + '/list';
        return await this.apiEngine.get(link)
    }

    async getItemlistParamDirect(path, param) {
        const link = '/api/admin/' + path + '/list/' + param;
        console.warn(link);
        return await this.apiEngine.get(link)
    }


    getBikelist(callback) {
        const link = '/api/admin/bike/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    getHive(callback) {
        const link = '/api/admin/hive/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getForaging(callback) {
        const link = '/api/admin/foraging/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getType(callback) {
        const link = '/api/admin/type/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getHints(callback) {
        const link = '/api/admin/hints/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }



    getCrops(callback) {
        const link = '/api/admin/crops/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getSensors(callback) {
        const link = '/api/admin/sensor/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getFarmerSensors(callback){
        const link = '/api/farmer/sensor/list'
        this.apiEngine.post(link)
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    getLands(callback) {
        const link = '/api/admin/land/list';
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    getFarmerLands(callback){
        const link = '/api/farmer/land/list'
        this.apiEngine.post(link)
        .then((response) => {
            callback(response);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    getFarmingTips(callback) {
        const link = "/api/admin/farmingtips/list";
        console.warn(link);
        this.apiEngine.get(link)
            .then(response => {
                callback(response)
            })
            .catch(e => {
                console.log(e)
            })
    }
    saveFarmingTip(data, callback) {
        const link = '/api/admin/farmingtips/save';
        console.warn(link);

        this.apiEngine.post(link, data)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    deleteLandImage(data, callback) {
        const link = '/api/landimage/' + data.landID + '/' + data.imageNB;
        this.apiEngine.delete(link)
            .then(response => {
                callback(true)
            })
            .catch(err => {
                callback(false)
            })

    }
    deleteFarmingTipImage(data, callback) {
        const link = '/api/farmingtipimage/' + data.farmingTipID + '/' + data.imageNB;
        this.apiEngine.delete(link)
            .then(response => {
                callback(true)
            })
            .catch(err => {
                callback(false)
            })

    }
    deleteZoneImage(data, callback) {
        const link = '/api/zoneimage/' + data.zoneID + '/' + data.imageNB;
        this.apiEngine.delete(link)
            .then(response => {
                callback(true)
            })
            .catch(err => {
                callback(false)
            })
    }
    deleteUserImage(data, callback) {
        const link = '/api/userimage/' + data.userID;
        this.apiEngine.delete(link)
            .then(response => {
                callback(true)
            })
            .catch(err => {
                callback(false)
            })
    }
    getNoti(callback) {
        const link = '/api/admin/notification/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    getPanic(callback) {
        const link = '/api/admin/panic/list';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getUsers(manager, callback) {
        let link = '/api/admin/user/list';
        if (manager) {
            link = '/api/admin/manager/list';
        }
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getManagerProfile(callback){
        let link= '/api/admin/farmer/user'
        this.apiEngine.post(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }



    getSettingsinfo(callback) {
        const link = '/api/admin/settings';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    getdashboard(callback) {
        const link = '/api/admin/dashboard';
        console.warn(link);
        this.apiEngine.get(link)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    saveItem(data, path, callback) {
        const link = '/api/admin/' + path + '/save';
        console.warn(link);
        this.apiEngine.post(link, data)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    saveBike(data, callback) {
        const link = '/api/admin/bike/save';
        console.warn(link);
        this.apiEngine.post(link, data)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }




    saveUserManager(data, callback) {
        const link = '/api/admin/manager/save';
        console.warn(link);
        this.apiEngine.post(link, data)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    saveUser(formData, callback) {
        const link = '/api/admin/user/save';
        console.warn(link);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        this.apiEngine.post(link, formData, config)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }




    saveCrops(data, callback) {
        const link = '/api/admin/crops/save';
        console.warn(link);

        this.apiEngine.post(link, data)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    //  saveLand(data,callback) {
    //      const link = '/api/admin/land/save';
    //      console.warn(link);

    //      this.apiEngine.post(link,data)
    //          .then((response) => {
    //              callback(response);
    //          })
    //          .catch((error) => {
    //              console.log(error);
    //          });
    //  }

    saveLand(zid, data, callback) {
        let port = 3000 + parseInt(zid)
        console.log(port)
        let token = sessionStorage.getItem('token');
        let url = `http://167.71.34.144:${port}/api/admin/land/save`
        console.log(url)
        axios
            .post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }



    saveSensor(zid, data, callback) {
        //const link = '/api/admin/sensor/save';
        let port = 3000 + parseInt(zid)
        console.log(port)
        let token = sessionStorage.getItem('token');
        let url = `http://167.71.34.144:${port}/api/admin/sensor/save`
        console.warn(url);
        axios
            .post(url, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': token
                }
            })
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    saveHints(data, callback) {
        const link = '/api/admin/hints/save';
        console.warn(link);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        this.apiEngine.post(link, data, config)
            .then((response) => {
                callback(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }






}

export default RequestEngine;
