import React, { Component } from "react";
import { OrbitSpinner } from 'react-epic-spinners'


import NotificationAlert from "react-notification-alert";
class CoreEngine extends Component {

    componentWillMount() {
        let isloggedin = sessionStorage.getItem('isloggedin');
        if (!isloggedin || isloggedin === "false") {
            //   this.props.history.push('/auth/login');
        }
    }

    changeInput = (event, stateName) => {
        if (!event) {
            this.setState({ [stateName]: "" });
        } else
            if (event && event.target) {
                this.setState({ [stateName]: event.target.value });
            } else if (event && event.length) {
                this.setState({ [stateName]: event });
            } else {
                this.setState({ [stateName]: event.value }); // select input
            }
    };

    formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }


    createSelectValue(array, withslug = true) {
        let data = [];
        array.map((item) => {

            data.push({ value: item.trim(), label: item.trim() })




        });
        return data;
    };


    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            (row[id] !== undefined && row[id] !== null) ?
                String(row[id].toString().toLowerCase()).includes(filter.value.toString().toLowerCase())
                :
                true
        );
    }

    renderDateFromTimeStamp(timestamp) {
        let moment = require('moment');
        const formatted = moment.unix(timestamp / 1000).format("DD/MM/YYYY HH:mm");
        return <span>
            {formatted}
        </span>
    }

    renderDate(item) {
        if (!item) {
            return "-"
        }
        let moment = require('moment');

        let obj = moment(item).utcOffset(item)

        // const newdate = obj.format('DD/MM/YYYY HH:mm')
        const newdate = obj.format('DD/MM/YYYY ')
        return newdate
    }

    renderDateTimeZone(item) {
        if (!item) {
            return "-"
        }
        let moment = require('moment-timezone');
        const newdate = moment(item).tz("America/New_York").format('DD/MM/YYYY HH:mm')
        return newdate
    }
    renderProgress() {
        return (
            <div className="content">
                <div className="col-md-2 ml-auto mr-auto">

                    <div className="logo">

                        <div className="logo-img">
                            <OrbitSpinner color="#51BCDA" className="loadingLogo" />
                        </div>

                    </div>

                </div>
            </div>
        )
    }

}

export default CoreEngine;


