import React from "react";
import PictureUpload from '../../components/CustomUpload/PictureUpload'
// reactstrap misc
import './style.css'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import SelectRow from "../../components/Select/SelectRow"
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";



export default class EditFarmingTip extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            title:"",
            message:"",
            pictureFile: null,
            pictureURL: null,
            picture2File: null,
            picture2URL: null,
            picture3File: null,
            picture3URL: null
        };
        this.engine = new RequestEngine();
    }



    componentDidMount() {
        const edit = this.props.location.data
        if (edit) {
            this.setState({
                title:edit.title,
                _id: edit._id,
                message:edit.message,
                pictureURL: edit.image ?  edit.image:null,
                picture2URL: edit.image2 ? edit.image2: null,
                picture3URL: edit.image3  ?  edit.image3:null,

            });


        }

        // this.prepareData()


    }
    handlePictureFileChange = (file) => {
        this.setState({ pictureFile: file })

    }
    handlePicture2FileChange = (file) => {
        this.setState({ picture2File: file })

    }
    handlePicture3FileChange = (file) => {
        this.setState({ picture3File: file })

    }
    // async prepareData() {
    //     let responseuni = await this.engine.getItemlistDirect("user");
    //     if (responseuni) {
    //         const data = responseuni.data.data;
    //         let managertypelist = []
    //         data.map(p =>
    //             managertypelist.push({ value: p._id, label: p.name })
    //         )

    //         this.setState({
    //             managertypelist: managertypelist
    //         });
    //     }



    // }

    handleImageDelete =async (imageNB)=>{
        this.engine.deleteFarmingTipImage({farmingTipID:this.state._id,imageNB},(response)=>{
            if(response){
                switch(parseInt(imageNB)){
                    case 1:this.setState({pictureURL:undefined});break;
                    case 2:this.setState({picture2URL:undefined});break;
                    case 3:this.setState({picture3URL:undefined});break;
                }
            }
        })
    }

    handleValidSubmit = () => {
        
        const formData = new FormData();
        const { title,message } = this.state;
        formData.append("title", title);
        formData.append("message", message);
        formData.append("image", this.state.pictureFile);
        formData.append("image2", this.state.picture2File);
        formData.append("image3", this.state.picture3File);

        const edit = this.props.location.data;
        if (edit) {
            formData.append('_id', this.state._id);
        }
        if(!edit){
            if(!this.state.pictureFile){alert("You need to enter at least the first image.");return;}
        }
        this.setState({
            btnloading: true
        })
        // this.engine.saveLand(this.state, (response) => {
        this.engine.saveFarmingTip(formData, (response) => {
            console.log(response.status);
            if (response.status === 200) {
                this.setState({
                    btnloading: false
                })
                this.props.history.push('/admin/farmingtips');
            }
        });

    };




    renderinfo() {

        const { title,message, _id } = this.state

        return (<>
            
            <FormRow label="title" name="title" data={this.state.title} changeInput={this.changeInput.bind(this)} />
            <FormRow label="message" type="textarea" name="message" data={this.state.message} changeInput={this.changeInput.bind(this)} />
            <div className="editLand-pictureContainer" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            <PictureUpload fromLand={true} imageNB={1} handleImageDelete={this.handleImageDelete.bind(this)} setPictureFile={this.handlePictureFileChange.bind(this)} pictureURL={this.state.pictureURL} />
                <PictureUpload fromLand={true} imageNB={2} handleImageDelete={this.handleImageDelete.bind(this)} setPictureFile={this.handlePicture2FileChange.bind(this)} pictureURL={this.state.picture2URL} />
                <PictureUpload fromLand={true} imageNB={3} handleImageDelete={this.handleImageDelete.bind(this)} setPictureFile={this.handlePicture3FileChange.bind(this)} pictureURL={this.state.picture3URL} />
            </div>

        </>)
    }
    render() {
        // taking all the states
        const {
            btnloading, _id } = this.state;
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        {this.renderinfo()}



                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


