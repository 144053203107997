import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Label,
    FormGroup,
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import ImageUpload from "../../components/CustomUpload/ImageUpload";

export default class HintsAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            title: "",
            fullpicture: "",
            picture:"",
            _id : "",

        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        const edit = this.props.location.data
        if (edit){
            this.setState({
                title: edit.title,
                content: edit.content,
                _id: edit._id,
            });
        }

    }


    handleValidSubmit = () => {

        this.setState({
            btnloading: true
        })

        const {_id,picture,title} = this.state
        const formData = new FormData();
        formData.append('picture',picture)
        formData.append('_id',_id)
        formData.append('title',title)
        this.engine.saveHints(formData,(response) => {
            console.log(response.status);
            if(response.status === 200){
                this.props.history.push('/admin/hints');
            }
            this.setState({
                btnloading: false
            })
        });

    };
    onChangePic(e) {
        this.setState({picture:e.target.files[0]})
    }

    render() {
        // taking all the states
        const {title,description,btnloading,_id} = this.state;
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <FormRow label="Title" name="title" data={title} changeInput={this.changeInput.bind(this)} />
                                        <Row>
                                            <Label sm="2">Picture</Label>
                                            <Col sm="7">
                                                <FormGroup >
                                                    <ImageUpload placeholder={this.state.fullpicture} labelupload="Select Picture" onChange={(e) => this.onChangePic(e)}/>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


