import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import { connect } from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";

class UserList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {

        const { manager } = this.props
        console.log("manager", manager)
        this.callPage(manager)
    }

    handleJourny(valueid) {
        this.props.history.push('/admin/user/journey/' + valueid);
    }

    handleDelete(valueid) {
        
        this.props.loadingAction(true)
        const { manager } = this.props
        console.log(manager);
        if(manager){
            this.engine.deleteManager(valueid, (response) => {
                console.log(response.status);
                if (response.status === 200) {
                    this.handlePage(manager);
                }
                this.props.loadingAction(false)
            });
        }else{
            this.engine.deleteUser(valueid, (response) => {
                console.log(response.status);
                if (response.status === 200) {
                    this.handlePage(manager);
                }
                this.props.loadingAction(false)
            });
        }
    }

    handleToggleActivate(valueid) {
        this.props.loadingAction(true)
        const { manager } = this.props
        if(manager){
            this.engine.activateManager(valueid, (response) => {
                console.log(response.status);
                if (response.status === 200) {
                    this.handlePage(manager);
                }
                this.props.loadingAction(false)
            });
        }else{
            this.engine.activateUser(valueid, (response) => {
                console.log(response.status);
                if (response.status === 200) {
                    this.handlePage(manager);
                }
                this.props.loadingAction(false)
            });
        }
    }


    handleEdit(index, dataid) {
        const data = this.state.dataSource[index].data
        const { manager } = this.props
        if (manager) {
            this.props.history.push({
                pathname: '/admin/manager/edit/' + dataid,
                data
            });
        } else {
            this.props.history.push({
                pathname: '/admin/user/edit/' + dataid,
                data
            });
        }
    }


    handleActions(index, item) {

        const { manager } = this.props
        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleEdit(index, item._id)}>Edit</DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleToggleActivate(item._id)}>{item.status == 0 ? "Activate" : "Deactivate"} </DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>{item.deleted && "Restore"} {!item.deleted && "Delete"}</DropdownItem>

            </DropdownMenu>
        </UncontrolledDropdown>)
    }


    toggelVerified(dataid) {
        const { manager } = this.props
        this.engine.toggleuserstatus(dataid._id, manager, (response) => {
            console.log(response.status);
            if (response.status === 200) {
                //this.iniload();
                //window.location.reload()
                this.componentDidMount()
            }
        });
    }
    checkstatus(item) {
        if (item.deleted) {
            return <b style={{ color: "red" }}>Deleted</b>;
        } else
            if (item.status === 0) {

                return (
                    <>
                        <Button
                            className="btn-round  btn-neutral btn-toggleUserActivation"
                            color="danger"
                            id={'tooltip' + item._id}
                            title=""
                            type="button"
                        >
                            Unactivated
                        </Button>
                    </>
                )

            } else if (item.status === 1) {

                return (
                    <>
                        <Button
                            className="btn-round  btn-neutral btn-toggleUserActivation"
                            color="success"
                            id={'tooltip' + item._id}
                            title=""
                            type="button"
                        >
                            Activated
                        </Button>
                    </>
                )
            }

    }


    putrowstatus(item) {
        if (item.deleted) {
            return "Deleted"
        } else
            if (item.status === 0) {

                return "Unverified"

            } else if (item.status === 1) {

                return "Verified"
            }

    }
    callPage(manager) {
        this.engine.getUsers(manager, (response) => {
            console.log(response.status);
            if (response.status === 200) {
                this.setState({
                    isLoading: false,
                    dataSource: response.data.data.map((item, key) => {
                        return {
                            id: key,
                            data: item,
                            name: item.name,
                            type: item.managertype && item.managertype.name,
                            status: this.checkstatus(item),
                            raw_status: this.putrowstatus(item),
                            subscribetype: item.subscribetype && item.subscribetype,
                            email: item.email ? item.email : ' ',
                            message: item.message ? item.message : ' ',
                            phone: item.phone,
                            created: this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key, item)
                            )
                        };
                    })
                }
                );
            }
            this.props.loadingAction(false);
        });
    }

    handlePage(manager) {
        this.props.loadingAction(true);
        this.callPage(manager)
    }


    handleAdd() {

        const { manager } = this.props

        if (manager) {
            this.props.history.push('/admin/manager/add');
        } else {
            this.props.history.push('/admin/user/add');
        }

    }
    filterRawText(filter, row) {
        // Pivoted rows won't contain the column.
        //  If that's the case, we set the to true (allowing us to only filter on the current column)
        let rowValue = row[filter.id];
        if (!rowValue) {
            return true;
        }
        const index = row._index


        rowValue = this.state.dataSource[index]["raw_" + filter.id]
        if (!rowValue) {
            return;
        }
        const filterValue = filter.value;
        //const filterValue = filter.value.filterValue || "";
        //const filterType = filter.value.filterType;

        const filterType = "contains";
        switch (filterType) {
            case "contains":
                return rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
            case "starts-with":
                return rowValue.startsWith(filterValue);
            case "ends-with":
                return rowValue.endsWith(filterValue);
            case "matches":
                return rowValue === filterValue;
            case "greater-than":
                return rowValue > filterValue;
            case "less-than":
                return rowValue < filterValue;
            default:
                return true;
        }
    }


    render() {

        const { dataSource, isLoading } = this.state;
        const { manager } = this.props
        if (isLoading) {
            return this.renderProgress()
        }
        //old columns
        // let columns = [
        //     {
        //         Header: "Name",
        //         accessor: "name"
        //     },



        //     {
        //         Header: "Status",
        //         accessor: "status",
        //         filterMethod: this.filterRawText.bind(this),
        //     },


        //     {
        //         Header: "Phone",
        //         accessor: "phone"
        //     },
        //     {
        //         Header: "Created",
        //         accessor: "created"
        //     },
        //     {
        //         Header: "Actions",
        //         accessor: "actions",
        //         sortable: false,
        //         filterable: false
        //     },

        // ]


        // if (!manager) {
        //     // columns.splice(2, 1)   // hide managertype //hides phone number
        // } else {
        //     columns.splice(4, 1)   // hide subscriber type //hides actions
        // }
        let columns = [
            {
                Header: "Name",
                accessor: "name",
                style: { 'whiteSpace': 'unset' }
            },


            {
                Header: "Phone",
                accessor: "phone",
                style: { 'whiteSpace': 'unset' }
            },

            {
                Header: "Email",
                accessor: "email",
                style: { 'whiteSpace': 'unset' }
            },
            {
                Header: "Created",
                accessor: "created",
                style: { 'whiteSpace': 'unset' },
                sortMethod: (a, b) => {
                    const [day1, month1, year1] = a.split('/');
                    const [day2, month2, year2] = b.split('/');
                    if (year1 > year2) { return 1; }
                    else if (year1 < year2) { return -1; }
                    if (month1 > month2) { return 1; }
                    if (month1 < month2) { return -1; }
                    if (day1 > day2) { return 1; }
                    if (day1 < day2) { return -1; }
                    return 0;
                }
            },
            {
                Header: "Message",
                accessor: "message",
                style: { 'whiteSpace': 'unset' }
            },
            {
                Header: "Status",
                accessor: "status",
                filterMethod: this.filterRawText.bind(this),
                sortMethod: (a, b) => {
                    if (a.props.children.props.color > b.props.children.props.color) { return 1 } else if (a.props.children.props.color === b.props.children.props.color) { return 0 } else { return -1 }
                }
            },



            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            },


        ]

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Users List</CardTitle>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.handleAdd()
                                    }>
                                        Add
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(UserList);