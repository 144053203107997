import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";

import ImageUpload from "../../components/CustomUpload/ImageUpload";


export default class CropAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            landlist:[],
            sensorlist:[],
            sensor:"",
            mos:"0",
            land:"",
            name: "",

            humidityminred: "",
            humiditymaxred: "",
            humidityminyellow: "",
            humiditymaxyellow: "",
            humiditymingreen: "",
            humiditymaxgreen: "",

            _id : ""
        };
        this.engine = new RequestEngine();
    }



    componentDidMount() {
        const edit = this.props.location.data

        if (edit){
            this.setState({
                ...edit,
                managertype:edit.user && edit.user._id,
                _id: edit._id,
            });


        }

        this.prepareData()
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("land");
        if(responseuni){
            const data = responseuni.data.data;
            let landlist = []
            data.map(p =>
                landlist.push( { value: p._id, label: p.name })
            )

            this.setState({
                landlist: landlist
            });
        }

        responseuni = await this.engine.getItemlistDirect("sensor");
        if(responseuni){
            const data = responseuni.data.data;
            let sensorlist = []
            data.map(p =>
                sensorlist.push( { value: p._id, label: p.name })
            )

            this.setState({
                sensorlist: sensorlist
            });
        }
    }



    handleValidSubmit = () => {

        this.setState({
            btnloading: true
        })
        this.engine.saveCrops(this.state,(response) => {
            console.log(response.status);
            if(response.status === 200){
                this.props.history.push('/admin/crops');
            }
            this.setState({
                btnloading: false
            })
        });

    };




    renderManagerinfo(){

        return (<>


            <FormRow label="name" name="name" data={this.state.name} changeInput={this.changeInput.bind(this)} />
            <FormRow label="mosture" name="mos" type={"number"} data={this.state.mos} changeInput={this.changeInput.bind(this)} />

            <FormRow label="humidity min red" name="humidityminred" data={this.state.humidityminred} changeInput={this.changeInput.bind(this)} />
            <FormRow label="humidity max red" name="humiditymaxred" data={this.state.humiditymaxred} changeInput={this.changeInput.bind(this)} />

            <FormRow label="humidity min yellow" name="humidityminyellow" data={this.state.humidityminyellow} changeInput={this.changeInput.bind(this)} />
            <FormRow label="humidity max yellow" name="humiditymaxyellow" data={this.state.humiditymaxyellow} changeInput={this.changeInput.bind(this)} />

            <FormRow label="humidity min green" name="humiditymingreen" data={this.state.humiditymingreen} changeInput={this.changeInput.bind(this)} />
            <FormRow label="humidity max green" name="humiditymaxgreen" data={this.state.humiditymaxgreen} changeInput={this.changeInput.bind(this)} />


        </>)
    }
    render() {
        // taking all the states
        const {    managertypelist,
            full_name,
            password,
            email,
            comments,
            phone,
            first_name,
            last_name,
            birthday,
            picture,
            gender,
            status,
            managertype,
            newpassword,
            btnloading,_id} = this.state;
        const {normaluser} = this.props
        const edit = this.props.location.data
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        {this.renderManagerinfo()}



                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


