import React from "react";
import PictureUpload from '../../components/CustomUpload/PictureUpload'
// reactstrap misc
import './style.css'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import SelectRow from "../../components/Select/SelectRow"
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";



export default class EditLand extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            landid: "", // zid
            name: "",
            danam: "",
            _id: "",
            x_v: "",
            location: "",
            type: "",
            x_t: "",
            x_ws: "",
            fl: "",
            pictureFile: null,
            pictureURL: null,
            picture2File: null,
            picture2URL: null,
            picture3File: null,
            picture3URL: null,
            typelist: this.createSelectValue(["حديقة", "أرض زراعية", "مشتل", "إلخ"], false),
            managertypelist: [],
        };
        this.engine = new RequestEngine();
    }



    componentDidMount() {
        const edit = this.props.location.data

        if (edit) {
            this.setState({
                ...edit,
                managertype: edit.user && edit.user._id,
                land: edit.land && edit.land._id,
                _id: edit._id,
                type: edit.landtype,
                pictureURL: edit.picture === 'dummyimg.jpg' ? null : edit.picture,
                picture2URL: edit.picture2 === 'dummyimg.jpg' ? null : edit.picture2,
                picture3URL: edit.picture3 === 'dummyimg.jpg' ? null : edit.picture3,

            });


        }

        this.prepareData()


    }
    handlePictureFileChange = (file) => {
        this.setState({ pictureFile: file })

    }
    handlePicture2FileChange = (file) => {
        this.setState({ picture2File: file })

    }
    handlePicture3FileChange = (file) => {
        this.setState({ picture3File: file })

    }
    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("user");
        if (responseuni) {
            const data = responseuni.data.data;
            let managertypelist = []
            data.map(p =>
                managertypelist.push({ value: p._id, label: p.name })
            )

            this.setState({
                managertypelist: managertypelist
            });
        }



    }


    handleValidSubmit = () => {
        const formData = new FormData();
        const { landid, name, danam, _id, x_v, location, type, x_t, x_ws, fl, typelist, managertypelist } = this.state;
        formData.append("landid", landid);
        formData.append("name", name);
        formData.append("danam", danam);
        formData.append("_id", _id);
        formData.append("x_v", x_v);
        formData.append("location", location);
        formData.append("type", type);
        formData.append("x_t", x_t);
        formData.append("x_ws", x_ws);
        formData.append("fl", fl);
        formData.append("typelist", typelist);
        formData.append("managertypelist", managertypelist);

        formData.append("picture", this.state.pictureFile);
        formData.append("picture2", this.state.picture2File);
        formData.append("picture3", this.state.picture3File);

        const edit = this.props.location.data;
        if (edit) {
            formData.append('land', this.state.land);
            formData.append('managertype', this.state.managertype);
        }
        if (!edit) {//a new land is associated with a selected user
            formData.append('managertype', this.state.managertype);

        }
        this.setState({
            btnloading: true
        })
        // this.engine.saveLand(this.state, (response) => {
        this.engine.saveLand(landid ,formData, (response) => {
            console.log(response.status);
            if (response.status === 200) {
                this.setState({
                    btnloading: false
                })
                this.props.history.push('/admin/land');
            }
        });

    };
    handleImageDelete =async (imageNB)=>{
        this.engine.deleteLandImage({landID:this.state._id,imageNB},(response)=>{
            if(response){
                switch(parseInt(imageNB)){
                    case 1:this.setState({pictureURL:undefined});break;
                    case 2:this.setState({picture2URL:undefined});break;
                    case 3:this.setState({picture3URL:undefined});break;
                }
            }
        })
    }



    renderinfo() {

        const { x_v,
            x_t,
            x_ws,
            fl,
            location,
            i_mst, type, typelist,
            x_mst, _id } = this.state

        return (<>
            {!_id && <Row>
                <Label sm="2">User</Label>
                <Col sm="7">
                    <FormGroup>


                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="managertype"
                            isMulti={false}
                            isSearchable={false}
                            value={this.state.managertypelist.find(v => v.value === this.state.managertype)}
                            onChange={data =>
                                this.setState({ managertype: data.value })
                            }
                            options={this.state.managertypelist}
                            placeholder="Please select "
                        />
                    </FormGroup>
                </Col>
            </Row>}
            <SelectRow label="Type" name="type" defaultValue={type} data={typelist} changeInput={this.changeInput.bind(this)} />
            <FormRow label="name" name="name" data={this.state.name} changeInput={this.changeInput.bind(this)} />
            <FormRow label="land id" name="landid" data={this.state.landid} changeInput={this.changeInput.bind(this)} />

            <FormRow label="danam" name="danam" data={this.state.danam} changeInput={this.changeInput.bind(this)} />
            <FormRow label="location" name="location" data={this.state.location} changeInput={this.changeInput.bind(this)} />

            <FormRow label="maximum number of valves" name="x_v" type={"number"} data={x_v} changeInput={this.changeInput.bind(this)} />
            <FormRow label="maximum temperature" name="x_t" type={"number"} data={x_t} changeInput={this.changeInput.bind(this)} />
            <FormRow label="maximum wind speed" name="x_ws" type={"number"} data={x_ws} changeInput={this.changeInput.bind(this)} />
            <div className="editLand-pictureContainer" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <PictureUpload fromLand={true} imageNB={1} handleImageDelete={this.handleImageDelete.bind(this)} setPictureFile={this.handlePictureFileChange.bind(this)} pictureURL={this.state.pictureURL} />
                <PictureUpload fromLand={true} imageNB={2} handleImageDelete={this.handleImageDelete.bind(this)} setPictureFile={this.handlePicture2FileChange.bind(this)} pictureURL={this.state.picture2URL} />
                <PictureUpload fromLand={true} imageNB={3} handleImageDelete={this.handleImageDelete.bind(this)} setPictureFile={this.handlePicture3FileChange.bind(this)} pictureURL={this.state.picture3URL} />
            </div>

        </>)
    }
    render() {
        // taking all the states
        const {
            btnloading, _id } = this.state;
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        {this.renderinfo()}



                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


