import React from "react";

// reactstrap misc
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import CoreEngine from "../core/CoreEngine";
import RequestEngine from "../core/RequestEngine";


class Dashboard extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            usercount: 0,
            pendingquestion : 0,
            usersubscribe:0,
            bikecount: 0,
            maintenancelist: [],
            paniclist: []
        };
    }


    componentDidMount() {
        this.engine = new RequestEngine();
        this.callPage()
    }

    callPage() {
        this.engine.getdashboard((response) => {
            console.log(response.status);
            if(response.status === 200){
                this.setState({
                    isLoading: false,
                    usercount: response.data.data.usercount,
                    bikecount: response.data.data.bikecount,
                    usersubscribe: response.data.data.usersubscribe,
                    pendingquestion: response.data.data.pendingquestion,
                    maintenancelist: response.data.data.maintenancelist,
                    paniclist: response.data.data.paniclist
                });
            }
        });
    }


    viewbike(){
        this.props.history.push('/admin/land');

    }

    viewuser(){
        this.props.history.push('/admin/user');
    }
  render() {

      const { usercount,
          pendingquestion ,
          usersubscribe,
          bikecount,
          maintenancelist,
          paniclist,isLoading} = this.state;
      if (isLoading) {
          return this.renderProgress()
      }
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-single-02 text-warning" />
                      </div>
                    </Col>
                    <Col md="9" xs="7">
                      <div className="numbers">
                        <p className="card-category">Users</p>
                        <CardTitle tag="p">{usercount}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                  <CardFooter>
                      <hr />
                      <div className="stats">
                          <i className="fa fa-calendar-o" />

                          <a href="#" onClick={() => this.viewuser()} >
                              View All
                          </a>


                      </div>
                  </CardFooter>

              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="3" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-paper text-success" />
                      </div>
                    </Col>
                    <Col md="9" xs="7">
                      <div className="numbers">
                        <p className="card-category">Land</p>
                        <CardTitle tag="p">{bikecount}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar-o" />
                    <a href="#" onClick={() => this.viewbike()} >
                      View All
                    </a>
                  </div>
                </CardFooter>
              </Card>
            </Col>


          </Row>

          <Row>
            <Col md="6">
              <Card className="card-tasks">
                <CardHeader>
                  <CardTitle tag="h4">Latest User</CardTitle>
                  <h5 className="card-category">new user </h5>
                </CardHeader>
                <CardBody>
                  <div className="table-full-width table-responsive">
                    <Table>
                        <thead className="text-primary">
                        <tr>
                            <th >Name</th>
                            <th >Phone</th>
                            <th  >Created</th>
                        </tr>
                        </thead>
                      <tbody>
                      {maintenancelist.map((item, index) => {
                        return (
                            <tr>
                                <td className="text-left">
                                    {item.name}
                                </td>
                                <td className="td-actions ">
                                    {item.phone}
                                </td>
                                <td className="td-actions ">
                                    {this.renderDate(item.createdAt)}
                                </td>
                            </tr>
                        )
                      })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <CardFooter>
                  <hr />

                </CardFooter>
              </Card>
            </Col>

              <Col md="6">
                  <Card className="card-tasks">
                      <CardHeader>
                          <CardTitle tag="h4">Latest Land</CardTitle>
                          <h5 className="card-category">new </h5>
                      </CardHeader>
                      <CardBody>
                          <div className="table-full-width table-responsive">
                              <Table>
                                  <thead className="text-primary">
                                  <tr>
                                      <th >Name</th>
                                      <th >Location</th>
                                      <th  >Created</th>
                                  </tr>
                                  </thead>
                                  <tbody>

                                  {paniclist.map((item, index) => {
                                      return (
                                          <tr>
                                              <td className="text-left">
                                                  {item.name}
                                              </td>
                                              <td className="td-actions ">
                                                  {item.location }
                                              </td>
                                              <td className="text-right ">
                                                  {this.renderDate(item.createdAt)}
                                              </td>
                                          </tr>
                                      )
                                  })}

                                  </tbody>
                              </Table>
                          </div>
                      </CardBody>
                      <CardFooter>
                          <hr />

                      </CardFooter>
                  </Card>
              </Col>

          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
