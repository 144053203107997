import React from "react";
import './style.css'
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import Select from "react-select";
import PictureUpload from '../../components/CustomUpload/PictureUpload'
import ImageUpload from "../../components/CustomUpload/ImageUpload";


export default class UserAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            managertypelist: [],
            full_name: "",
            fullpicture: "",
            profilePictureURL: null,
            profilePictureFile: null,
            phone: "",
            password: "",
            email: "",
            comments: "",
            managertype: "",
            first_name: "",
            name: "",
            last_name: "",
            birthday: "",
            location: "",
            picture: "",
            gender: "",
            status: "",
            newpassword: "",
            _id: "",
            farmerPlan: "",
            // notifications: false
        };
        this.engine = new RequestEngine();
    }


    handleProfilePictureChange = (pictureFile) => {
        this.setState({
            profilePictureFile: pictureFile
        })
    }
    componentDidMount() {
        const edit = this.props.location.data
        const { normaluser } = this.props
        if (edit) {
            this.setState({
                full_name: edit.full_name,
                first_name: edit.first_name,
                name: edit.name,
                location: edit.location,
                last_name: edit.last_name,
                birthday: edit.birthday,
                // picture: edit.picture,
                gender: edit.gender,
                password: '',
                email: edit.email,
                phone: edit.phone,
                comments: edit.comments,
                managertype: edit.managertype && edit.managertype._id,
                profilePictureURL: edit.profilePicture,
                profilePictureFile: null,
                farmerPlan: edit.farmerPlan,
                // notifications: edit.notifications ? edit.notifications : false,
                // fullpicture: edit.fullpicture,
                _id: edit._id,
            });
        }
        else if(sessionStorage.getItem('managertype')!=='admin'){
            this.engine.getManagerProfile(response=>{
                if (response.status === 200) {
                    const user = response.data.data
                    this.setState({
                        full_name: user.full_name,
                        first_name: user.first_name,
                        name: user.name,
                        location: user.location,
                        last_name: user.last_name,
                        birthday: user.birthday,
                        gender: user.gender,
                        password: '',
                        email: user.email,
                        phone: user.phone,
                        comments: user.comments,
                        managertype: user.managertype,
                        profilePictureURL: user.profilePicture,
                        profilePictureFile: user,
                        farmerPlan: user.farmerPlan,
                        _id: user._id,
                    });
                }
            })
        }

        this.prepareData()
    }

    async prepareData() {
        let responseuni = await this.engine.getItemlistDirect("managertype");
        if (responseuni) {
            const data = responseuni.data.data;
            let managertypelist = []
            data.map(p =>
                managertypelist.push({ value: p._id, label: p.name })
            )

            this.setState({
                managertypelist: managertypelist
            });
        }
    }



    handleValidSubmit = () => {

        this.setState({
            btnloading: true
        })

        const { normaluser } = this.props
        if (normaluser) {

            let data = this.state
            console.log("handleAdd ", data);
            const { managertypelist,
                full_name,
                password,
                email,
                location,
                phone,
                first_name,
                last_name,
                birthday,
                picture,
                gender,
                status,
                newpassword,
                name, farmerPlan,
                btnloading, _id, profilePictureFile } = this.state;
            const formData = new FormData();
            // formData.append('picture', picture)
            formData.append('picture', profilePictureFile)
            formData.append('_id', _id)
            formData.append('password', password)
            formData.append('email', email)
            formData.append('location', location)
            formData.append('phone', phone)
            formData.append('name', name)
            formData.append('birthday', birthday)
            formData.append('gender', gender)
            formData.append('status', status)
            formData.append('newpassword', newpassword)
            formData.append('farmerPlan', farmerPlan)
            // formData.append('notifications', notifications)

            // return;
            this.engine.saveUser(formData, (response) => {
                console.log(response.status);
                if (response.status === 200) {
                    this.props.history.push('/admin/user');
                } else {
                    alert("error")
                }
                // this.setState({
                //     btnloading: false
                // })
            });
        } else {
            this.engine.saveUserManager(this.state, (response) => {
                const type = sessionStorage.getItem('managertype');
                if (response.status === 200 ) {
                    if(type==='admin'){
                        this.props.history.push('/admin/manager');
                    }
                }
                this.setState({
                    btnloading: false
                })
            });
        }
    };


    onChangePic(e) {
        this.setState({ picture: e.target.files[0] })
    }
    renderSubscribedinfo() {

        return (<>
            <FormRow label="Name" name="name" data={this.state.name} changeInput={this.changeInput.bind(this)} />
            {/* BIRTHDAY */}
            {/* <FormRow label="Birthday" type="date" required={false} name="birthday" data={this.state.birthday} changeInput={this.changeInput.bind(this)} /> */}
            <FormRow required={false} label="Location" type="textarea" name="location" data={this.state.location} changeInput={this.changeInput.bind(this)} />


        </>)
    }

    renderManagerinfo() {

        return (<>
            {sessionStorage.getItem('managertype') === 'admin' &&<Row>
                <Label sm="2">Type</Label>
                <Col sm="7">
                    <FormGroup>


                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="managertype"
                            isMulti={false}
                            isSearchable={false}
                            value={this.state.managertypelist.find(v => v.value === this.state.managertype)}
                            onChange={data =>
                                this.setState({ managertype: data.value })
                            }
                            options={this.state.managertypelist}
                            placeholder="Please select "
                        />
                    </FormGroup>
                </Col>
            </Row>}
            <FormRow label="Full name" name="full_name" data={this.state.full_name} changeInput={this.changeInput.bind(this)} />


        </>)
    }
    handleImageDelete =async ()=>{
        this.engine.deleteUserImage({userID:this.state._id},(response)=>{
            if(response){
                this.setState({profilePictureURL:undefined})
            }
        })
    }

    // handleNotificationsToggle = (e) => {
    //     this.setState((prevState) => ({ notifications: !prevState.notifications }))
    // }
    render() {
        // taking all the states
        const { managertypelist,
            full_name,
            password,
            email,
            comments,
            phone,
            first_name,
            last_name,
            birthday,
            picture,
            gender,
            status,
            managertype,
            newpassword,
            btnloading, _id, farmerPlan } = this.state;
        const { normaluser } = this.props
        const edit = this.props.location.data
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <AvForm onValidSubmit={() => this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        {!normaluser && this.renderManagerinfo()}

                                        {normaluser && this.renderSubscribedinfo()}

                                        <FormRow label="Phone" name="phone" data={phone} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Email" type="email" errorMessage="Please put a valid email" name="email" data={email} changeInput={this.changeInput.bind(this)} />
                                        {/* {!edit && <FormRow label="Password " required={false} type="password" name="password" data={password} changeInput={this.changeInput.bind(this)} />} */}
                                        <FormRow label={`${edit ? 'New Password' : 'Password'}`} required={false} type="password" name="password" data={password} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="Farmer Plan " required={false} type="text" name="farmerPlan" data={farmerPlan} changeInput={this.changeInput.bind(this)} />
                                        {/* <div style={{ marginLeft: '3.5rem' }}>
                                            <label htmlFor="notifications" className="form-horizontal col-form-label form-horizontal col-form-label col-form-label">Notifications</label>
                                            <input className="notificationsCheckBox" style={{ height: '20px', width: '20px', marginLeft: '1rem' }} type="checkbox" id="notifications" onChange={this.handleNotificationsToggle.bind(this)} name="notifications" value={"notifications"} checked={this.state.notifications} />
                                        </div> */}
                                        {/* {!normaluser && (edit && <FormRow label="New Password " required={false} type="password" name="newpassword" data={newpassword} changeInput={this.changeInput.bind(this)} />)} */}
                                        <PictureUpload fromLand={false} handleImageDelete={this.handleImageDelete.bind(this)} setPictureFile={this.handleProfilePictureChange.bind(this)} pictureURL={this.state.profilePictureURL} />
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


