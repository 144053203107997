const Constants = {
  RTL: false,
  Language: "Arabic",
  // serverlink: 'http://192.168.1.105:3023',
  // serverlink: "http://localhost:3023",
  serverlink: "https://api.smartlandagri.com",

  timeout: 400000,
  GOOGLEAPIKEY: "AIzaSyCEIakI1L1Z5BeTRAcourwFtaHwg0QEAhs", // AIzaSyA1CXf4QFyGdoirCtIP6M_nzfhRX1jnhHo
};

export default Constants;
